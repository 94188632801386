@import "~bootstrap/scss/bootstrap";

/* color scheme */
$red: #DB5461;
$blue: #06213C;
$sand: #ECD0B8;
$black: #333333;
$purple: #CC76A1;

/* fonts */
$rokkit: 'Rokkitt', serif;
$ubuntu: 'Ubuntu Mono', monospace;

/* phone video transitions */
$backgroundPlaying: width 0.3s ease-out, height 0.3s ease-out, background-color 0.2s ease 0.3s, backdrop-filter 0.2s ease 0.3s;
$backgroundClosing: width 0.2s ease 0.3s, height 0.2s ease 0.3s, background-color 0.1s ease-out, backdrop-filter 0.1s ease-out;
$phonePlaying: all 0.4s ease 0.1s;
$phoneClosing: width 0.3s ease, transform 0.3s ease 0.3s, margin-top 0.3s ease 0.3s, margin-left 0.3s ease 0.3s;


html, body, #root {
  height: 100%;
  margin: 0;
  font-family: $ubuntu;
  color: $black; }

.remove-scroll {
  overflow: hidden; }

h1, h2, h3, h4, h5, h6 {
  font-family: $rokkit; }

p, span {
  font-family: $ubuntu; }

.red {
  color: $red;
  &--bold {
    font-weight: bold; } }

.florida {
  &__home {
    &--icon {
      font-size: 21px;
      position: absolute;
      z-index: 5;
      color: $black;
      &:hover {
        color: $red;
        cursor: pointer; } }
    &--container {
      background-color: $sand;
      background-image: url('./img/seamless-1807376.jpg');
      background-size: contain;
      background-repeat: repeat;
      color: $black;
      min-height: 100%;
      height: auto;
      position: relative; }
    &--title {
      background-color: $red;
      color: #FFFFFF;
      @include media-breakpoint-only(xs) {
        font-size: 28px; } }
    &--instructions {
      font-weight: bold; }
    &--state {
      @include media-breakpoint-down(sm) {
        display: none; }
      &--mobile {
        width: 25%; } }
    &--attr {
      @include media-breakpoint-only(xl) {
        position: absolute;
        bottom: 0;
        right: 0; }
      a {
        color: $black;
        text-decoration: underline;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        &:hover {
          color: $purple; } } } }
  &__mugshot {
    &--container {
      z-index: 9; }
    &--img {
      width: 100px;
      height: 100px;
      position: absolute;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      -webkit-box-shadow: 5px 5px 5px -4px rgba(102,102,102,0.3);
      -moz-box-shadow: 5px 5px 5px -4px rgba(102,102,102,0.3);
      box-shadow: 5px 5px 5px -4px rgba(102,102,102,0.3);
      @include media-breakpoint-only(lg) {
        width: 75px;
        height: 75px; }
      @include media-breakpoint-only(md) {
        width: 50px;
        height: 50px; }
      @include media-breakpoint-down(sm) {
        position: relative;
        margin: 15px 10px;
        top: 0!important;
        left: 0!important; }
      &:hover {
        cursor: pointer;
        -webkit-transform: scale(1.25);
        -ms-transform: scale(1.25);
        transform: scale(1.25);
        @include media-breakpoint-only(md) {
          -webkit-transform: scale(1.50);
          -ms-transform: scale(1.50);
          transform: scale(1.50); } } }
    &--berton-strong {
      top: -2%;
      left: -2%; }
    &--ben-jenkins {
      top: 5%;
      left: 25%; }
    &--albert-alvarez {
      top: -1%;
      left: 45%; }
    &--joel-jennings {
      top: -5%;
      left: 68%; }
    &--carl-skippins {
      top: 21%;
      left: 60%; }
    &--herman-douglas {
      top: 34%;
      left: 80%; }
    &--dewey-fleming {
      top: 53%;
      left: 65%; }
    &--donald-schmidt {
      top: 58%;
      left: 85%; }
    &--clam-jones {
      top: 79%;
      left: 77%; } }
  &__case-file {
    &--document {
      background-image: url('./img/paper-texture.jpg'), url('./img/paper.jpg');
      background-repeat: no-repeat, repeat;
      background-size: auto 100%, auto auto;
      background-position: left top, left top;
      background-color: #FFFFFF;
      padding: 0;
      margin: 20px 15px 0 15px;
      @include media-breakpoint-only(md) {
        margin: 20px 15px 0 75px; }
      @include media-breakpoint-only(sm) {
        padding: 0 0 355px 0;
        margin: 55px 15px 0 15px; }
      @include media-breakpoint-only(xs) {
        padding: 0 0 385px 0;
        margin: 55px 15px 0 15px; }
      &-logo {
        text-align: center;
        padding-top: 30px;
        padding-bottom: 20px;
        img {
          width: 45%;
          @include media-breakpoint-only(md) {
            width: 55%; }
          @include media-breakpoint-only(sm) {
            width: 65%; }
          @include media-breakpoint-only(xs) {
            width: 85%; } }
        h2 {
          @include media-breakpoint-down(md) {
            font-size: 1.5rem; } } }
      &-text {
        &-top {
          .row {
            border-left: 1px solid #555555;
            border-right: 1px solid #555555;
            &:nth-child(1) {
              border-top: 1px solid #555555; }
            &:last-child {
              border-bottom: 1px solid #555555; } }
          .filled-out {
            border-bottom: 1px solid #888888;
            font-weight: bold; } }
        &-incident {
          background-color: rgba(66, 158, 232, 0.13);
          font-weight: bold;
          font-size: 24px;
          height: 150px;
          z-index: 2;
          position: relative;
          @include media-breakpoint-down(sm) {
            height: auto; } } }
      &-coffee {
        width: 259px;
        height: auto;
        position: absolute;
        bottom: 200px;
        right: 100px;
        z-index: 1;
        opacity: 0.3; } }
    &--photo {
      width: fit-content;
      position: absolute;
      margin-top: -17px;
      margin-left: -60px;
      z-index: 20;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      @include media-breakpoint-only(lg) {
        margin-left: -40px; }
      @include media-breakpoint-down(md) {
        margin-left: -30px; }
      @include media-breakpoint-only(sm) {
        transform: rotate(20.5deg);
        margin-top: 130px; }
      @include media-breakpoint-only(xs) {
        right: 0;
        transform: rotate(-7.5deg);
        margin-top: 140px;
        margin-left: 0;
        margin-right: 0px; }
      &-container {
        position: relative;
        z-index: 5;
        width: 260px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        @include media-breakpoint-only(lg) {
          width: 220px; }
        @include media-breakpoint-only(md) {
          width: 200px; }
        @include media-breakpoint-only(sm) {
          width: 240px; }
        @include media-breakpoint-only(xs) {
          width: 200px; } }
      &-mugshot {
        position: absolute;
        top: 7%;
        left: 13%;
        width: 74%;
        z-index: 1;
        transform: rotate(-4.5deg); } }
    &--phone {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 360px;
      height: 500px;
      overflow: hidden;
      z-index: 50;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-down(lg) {
        width: 300px;
        height: 400px; }
      @include media-breakpoint-only(sm) {
        width: auto;
        height: 370px;
        right: auto;
        margin-left: -10px; }
      @include media-breakpoint-only(xs) {
        height: 570px;
        width: 100%;
        padding-top: 380px; }
      &.playing {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(3px);
        -webkit-transition: $backgroundPlaying;
        -moz-transition: $backgroundPlaying;
        -o-transition: $backgroundPlaying;
        transition: $backgroundPlaying;
        margin: 0;
        .florida__case-file--phone-img {
          transform: rotate(0deg);
          width: 800px;
          margin-top: auto;
          margin-left: auto;
          position: absolute;
          -webkit-transition: $phonePlaying;
          -moz-transition: $phonePlaying;
          -o-transition: $phonePlaying;
          transition: $phonePlaying; }
        @include media-breakpoint-down(sm) {
          position: fixed; }
        @include media-breakpoint-only(xs) {
          padding-top: 0; } }
      &.closing {
        -webkit-transition: $backgroundClosing;
        -moz-transition: $backgroundClosing;
        -o-transition: $backgroundClosing;
        transition: $backgroundClosing;
        .florida__case-file--phone-img {
          -webkit-transition: $phoneClosing;
          -moz-transition: $phoneClosing;
          -o-transition: $phoneClosing;
          transition: $phoneClosing; } }
      &-video {
        width: 420px;
        position: absolute;
        z-index: 15; }
      &-img {
        width: 600px;
        transform: rotate(90deg);
        margin-top: 250px;
        margin-left: 40px;
        @include media-breakpoint-down(lg) {
          width: 500px; }
        @include media-breakpoint-down(sm) {
          transform: rotate(0deg);
          width: 100%;
          margin-left: 0;
          margin-top: 0; }
        @include media-breakpoint-only(xs) {
          transform: rotate(90deg);
          width: 650px; } }
      &-play-btn {
        width: 175px;
        position: absolute;
        z-index: 10;
        left: 32%;
        top: 57%;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        &:hover {
          cursor: pointer;
          -webkit-transform: scale(1.15);
          -ms-transform: scale(1.15);
          transform: scale(1.15); }
        @include media-breakpoint-down(lg) {
          left: 27%;
          top: 54%; }
        @include media-breakpoint-down(sm) {
          left: auto;
          top: auto; } }
      &-close-btn {
        position: absolute;
        top: 0;
        right: 0;
        color: #FFFFFF;
        font-size: 54px;
        padding: 0 30px;
        font-family: $ubuntu;
        z-index: 100;
        &:hover {
          color: $red;
          cursor: pointer; }
        @include media-breakpoint-down(sm) {
          padding: 0 16px 8px;
          line-height: 1;
          background-color: rgba(219, 84, 97, 0.7);
          border-radius: 50%;
          font-size: 44px;
          margin: 10px 10px 0 0; } } } } }


.hamburger {
  &-menu {
    width: fit-content;
    height: auto;
    position: absolute;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.0);
    z-index: 5;
    transition: all 0.2s ease;
    .fa-bars {
      font-size: 21px;
      &:hover {
        color: $red;
        cursor: pointer; } }
    &__expanded {
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: rgba(0, 0, 0, 0.0);
      position: absolute;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      &.active {
        z-index: 99;
        background-color: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(3px);
        .sidebar {
          background-color: $red;
          color: #FFFFFF;
          width: 400px;
          overflow-y: auto;
          @include media-breakpoint-only(xs) {
            width: 100%; }
          a {
            opacity: 1;
            display: block; } } }
      .sidebar {
        width: 0;
        height: 100%;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        position: absolute;
        right: 0;
        padding-top: 50px;
        &-img {
          width: 50px;
          height: 50px; }
        .fa-home {
          font-size: 40px;
          margin-right: 9px;
          margin-left: 3px;
          vertical-align: sub; }
        .fa-bars {
          font-size: 21px;
          position: absolute;
          right: 0;
          top: 0;
          color: #FFFFFF;
          &:hover {
            color: $blue;
            cursor: pointer; } }
        a {
          opacity: 0;
          color: #FFFFFF;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: opacity 0.3s ease;
          padding: 7px 10px;
          height: 64px;
          font-size: 18px;
          display: none;
          &:hover {
              background-color: $blue;
              text-decoration: none; } } } } } }




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear; } }



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }

  to {
    transform: rotate(360deg); } }


